<template>
  <div class="home">
    <page-header title="Communiquer" icon="fa fa-bullhorn"></page-header>
    <b-row>
      <b-col cols="3">
        <div class="home1">
          <ul class="app-menu">
            <li>
              <router-link :to="{ name: 'contact-members', }">
                <i class="fa fa-envelope"></i> Contacter les membres
              </router-link>
            </li>
            <li>
               <router-link :to="{ name: 'newsletter-individuals', }">
                 <i class="fa fa-newspaper"></i> Inscrits à la newsletter
               </router-link>
             </li>
            <li v-if="hasPerm('log_books.view_logbook') && logBooks">
              <router-link :to="{ name: 'logbooks', }">
                <i class="fa fa-ship"></i> {{ logBooksTitle }}
              </router-link>
            </li>
            <li v-if="hasPerm('notes.view_noteexternalrecipient')">
              <router-link :to="{ name: 'note-external-recipients', }">
                <i class="fa fa-paper-plane"></i> Destinataires externes
              </router-link>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col>
        <div class="home2">
          <notes-summary></notes-summary>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/Layout/PageHeader'
import NotesSummary from '@/components/Notes/NotesSummary.vue'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'

export default {
  name: 'home',
  components: {
    NotesSummary,
    PageHeader,
  },
  mixins: [BackendMixin],
  mounted() {
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    nursery() {
      return store.getters.config.nursery
    },
    isStoreActive() {
      return store.getters.config.isStoreActive
    },
    isPublicationActive() {
      return store.getters.config.isPublicationActive
    },
    publicationTitle() {
      return store.getters.config.publicationTitle
    },
    isPortalActive() {
      return store.getters.config.isPortalActive
    },
    activityMenus() {
      if (this.hasPerm('activities.view_coreactivity')) {
        return store.getters.config.activityMenus
      }
      return []
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    canViewNotes() {
      return this.hasPerm('notes.view_note')
    },
    logBooks() {
      return store.getters.config.logBooks
    },
    logBooksTitle() {
      return (this.logBooks > 1) ? 'Journaux de bord' : 'Journal de bord'
    },
  },
  watch: {
  },
  methods: {
    reloadApp() {
      // force to reload the app after deleting the browser cache for this page
      // the forceReload flag is deprecated : so try without in case of error
      try {
        // tslint:disable-next-line:deprecation
        window.location.reload(true)
      } catch (e) {
        window.location.reload()
      }
    },
    showOnlineHelp() {
      this.$bvModal.show('bv-online-help')
    },
    showContactSupport() {
      this.$bvModal.show('bv-contact-support')
    },
  },
}
</script>

<style lang="less">
.show-online-help  {
  margin-right: 5px;
}
.home1 {
  padding: 9px 10px;
  height: 100vh;
}
.home2 {
  padding: 2px 10px;
  background: #eee;
  min-height: 86vh;
}
</style>
